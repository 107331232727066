<template>
  <form @submit.prevent="onSubmit">
    <PageLayout padding="1rem">
      <template #header>
        <PageHeader>
          <span class="display-1 text-center w-100" style="line-height: 64px"
            >Mot de passe oublié</span
          >
        </PageHeader>
      </template>
      <template #default>
        <fieldset class="p-4 text-sm infos">
          Si un compte existe avec l'adresse email indiquée, vous receverez un
          email avec les instructions pour rénitialiser votre mot de passe
        </fieldset>
        <BaseInput type="email" v-model="email">Adresse email</BaseInput>
        <p class="error" v-if="error">{{ error }}</p>
        <BaseBtn class="mt-4" block :disabled="!email" :pending="isPending"
          >Réinitialiser</BaseBtn
        >
        <div class="links">
          <router-link :to="{ name: 'Login' }">Se connecter</router-link>
          |
          <router-link :to="{ name: 'Register' }">Créer un compte</router-link>
        </div>
      </template>
    </PageLayout>
  </form>
</template>

<script>
import { ref } from "vue";
import { projectAuth } from "../../firebase/config";
import { useRouter } from "vue-router";

export default {
  setup() {
    const email = ref("");
    const error = ref(null);
    const isPending = ref(null);
    const router = useRouter();
    const onSubmit = async () => {
      error.value = null;
      isPending.value = true;
      try {
        await projectAuth.sendPasswordResetEmail(email.value);
        email.value = "";
        // TODO: useFlash()
        alert(
          "Votre demande de réinitialisation de mot de passe a été envoyée !"
        );
        await router.push({ name: "Login" });
      } catch (err) {
        error.value = err.message;
      } finally {
        isPending.value = null;
      }
      // Todo: Modal with details { error, validation, router }
    };

    return { email, onSubmit, error, isPending };
  },
};
</script>

<style lang="scss" scoped></style>
